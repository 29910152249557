import SignUp from "../pages/SignUp/SignUp";
import SignIn from "../pages/SignIn/SignIn";
import Profile from "../pages/Profile/Profile";
import Quote from "../pages/Quote/Quote";
import PurchaseOrderDetail from "../pages/PurchaseOrderDetail/PurchaseOrderDetail";
import PurchaseOrder from "../pages/PurchaseOrder/PurchaseOrder";
import DeliveryCalendar from "../pages/DeliveryCalendar/DeliveryCalendar";
import ClientRequestFormDetail from "../pages/ClientRequestForm/ClientRequestFormDetail";
import SkuList from "../pages/SkuList/SkuList";
import SkuDetail from "../pages/SkuDetail/SkuDetail";
import QualityCheck from "../pages/QualityCheck/QualityCheck";
import QualityCheckDetail from "../pages/QualityCheckDetail/QualityCheckDetail";

const routes = [
  {
    path: "/signup",
    Component: SignUp,
    protected: false,
  },
  {
    path: "/signin",
    Component: SignIn,
    protected: false,
  },
  {
    path: "/profile",
    Component: Profile,
    protected: true,
  },
  {
    path: "/quotes",
    Component: Quote,
    protected: true,
  },
  {
    path: "/purchase-orders/:id",
    Component: PurchaseOrderDetail,
    protected: true,
  },
  {
    path: "/purchase-orders",
    Component: PurchaseOrder,
    protected: true,
  },
  {
    path: "/delivery-calendar",
    Component: DeliveryCalendar,
    protected: true,
  },
  {
    path: "/vendor-form/:id",
    Component: ClientRequestFormDetail,
    protected: true,
  },
  {
    path: "/skus",
    Component: SkuList,
    protected: true,
  },
  {
    path: "/skus/:id",
    Component: SkuDetail,
    protected: true,
  },
  {
    path: "/quality-checks",
    Component: QualityCheck,
    protected: true,
  },
  {
    path: "/quality-checks/detail/:id",
    Component: QualityCheckDetail,
    protected: true,
  },
];

export default routes;
