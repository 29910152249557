import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "TypoPRO Montserrat",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Medium.ttf",
      fontWeight: 500,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-SemiBold.ttf",
      fontWeight: 600,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Bold.ttf",
      fontWeight: 700,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-MediumItalic.ttf",
      fontStyle: "italic",
      fontWeight: 500,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-BoldItalic.ttf",
      fontStyle: "italic",
      fontWeight: 700,
    },
  ],
});
// Define styles
export const styles = StyleSheet.create({
  page: {
    fontFamily: "TypoPRO Montserrat",
    padding: "30px",
    zIndex: 20,
    position: "relative",
  },
  borderOut: {
    position: "absolute",
    padding: "20px",
    height: "100vh",
    width: "100vw",
    top: "0",
    left: "0",
  },
  borderIn: {
    border: "1px solid #8E8E8E",
    height: "100%",
    width: "100%",
  },
  pageHeader: {
    padding: 8,
    backgroundColor: "#FFF1E3",
    color: "#000059",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  pageHeaderLeftField: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    alignItems: "flex-start",
  },
  pageHeaderRightField: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    alignItems: "flex-end",
  },
  pageHeaderKey: {
    color: "#393A3A",
    fontWeight: 400,
    fontSize: "8px",
  },
  pageHeaderValue: {
    color: "#000059",
    fontSize: "8px",
    fontStyle: "italic",
    fontWeight: 600,
  },
  pageHeaderTitle: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "12px",
  },
  vendorAddressContainer: {
    marginTop: "32px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "8px",
  },
  vendorAddressLeft: {
    display: "flex",
    flexDirection: "column",
  },
  vendorAddressRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  vendorAddressTitle: {
    color: "#000059",
    fontWeight: "bold",
    paddingBottom: "8px",
  },
  vendorAddressContent: {
    color: "#000059",
  },
  qaStatsContainer: {
    marginTop: "32px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "8px",
  },
  qaStatsLeft: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  qaStatusPAContainer: {
    backgroundColor: "#CCFBF1",
    padding: "4px 6px",
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px",
    width: "120px",
  },
  qaStatusPA: {
    color: "#115E59",
    fontWeight: 500,
  },
  qaStatusAContainer: {
    backgroundColor: "#D8FFDE",
    padding: "4px 6px",
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px",
    width: "65px",
  },
  qaStatusA: {
    color: "#076100",
    fontWeight: 500,
  },
  qaStatusRContainer: {
    backgroundColor: "#FFF1F1",
    padding: "4px 6px",
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px",
    width: "65px",
  },
  qaStatusR: {
    color: "#AD0000",
    fontWeight: 500,
  },
  qaStatsRight: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    justifyContent: "flex-end",
  },
  flexRow: { display: "flex", flexDirection: "row" },
  qaProductKey: {
    fontWeight: 600,
    color: "#000059",
  },
  qaProductName: {
    fontWeight: 500,
    fontStyle: "italic",
    color: "#393A3A",
  },
  parameterHeaderContainer: {
    marginTop: "16px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F3FFEB",
    borderBottom: "1px solid #8E8E8E",
  },
  parameterHeader: {
    padding: "6px 12px",
    color: "#505050",
    fontSize: "8px",
    flex: 2,
  },
  valueHeader: {
    padding: "6px 12px",
    color: "#505050",
    fontSize: "8px",
    flex: 1,
  },
  statusHeader: {
    padding: "6px 12px",
    color: "#505050",
    fontSize: "8px",
    width: "50px",
  },
  parameterAnswerContainer: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #BCBCBC",
  },
  parameterAnswer: {
    padding: "6px 12px",
    color: "#393A3A",
    fontSize: "8px",
    flex: 2,
    fontWeight: 400,
    borderRight: "1px solid #BCBCBC",
  },
  valueAnswer: {
    padding: "6px 12px",
    color: "#393A3A",
    fontSize: "8px",
    fontStyle: "italic",
    flex: 1,
    backgroundColor: "#f4f4ff",
    fontWeight: 400,
    borderRight: "1px solid #BCBCBC",
  },
  statusAnswer: {
    padding: "6px 12px",
    width: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  statusImage: {
    width: "8px",
    height: "8px",
  },
  testHeaderContainer: {
    marginTop: "16px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F3FFEB",
    borderBottom: "1px solid #8E8E8E",
  },
  testNameHeader: {
    padding: "6px 12px",
    color: "#505050",
    fontSize: "8px",
    flex: 3,
  },
  remarkHeader: {
    padding: "6px 12px",
    color: "#505050",
    fontSize: "8px",
    flex: 1,
  },
  testAnswerContainer: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #BCBCBC",
  },
  testNameAnswer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    padding: "6px 12px",
    flex: 3,
    borderRight: "1px solid #BCBCBC",
  },
  testName: {
    color: "#393A3A",
    fontSize: "8px",
    fontWeight: 700,
  },
  testDescription: {
    color: "#8E8E8E",
    fontSize: "8px",
    fontWeight: 500,
  },
  testMethodAnswer: {
    color: "#8E8E8E",
    fontSize: "8px",
    fontWeight: 500,
    padding: "6px 12px",
    flex: 1,
    borderRight: "1px solid #BCBCBC",
  },
  testRemarkAnswer: {
    color: "#393A3A",
    fontSize: "8px",
    fontWeight: 500,
    padding: "6px 12px",
    flex: 1,
    backgroundColor: "#f4f4ff",
    borderRight: "1px solid #BCBCBC",
  },
  pageFooter: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    marginTop: 24,
    fontSize: "8",
    color: "#393A3A",
  },
  pageFooterName: {
    fontWeight: 500,
    color: "#000000",
  },
  attachmentHeaderContainer: {
    marginTop: "16px",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F3FFEB",
    borderBottom: "1px solid #8E8E8E",
  },
  attachmentHeader: {
    padding: "6px 12px",
    color: "#505050",
    fontSize: "8px",
    flex: 1,
  },
  attachmentAnswerContainer: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #BCBCBC",
  },
  attachmentList: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    padding: "6px 12px",
    gap: "8px",
    flexWrap: "wrap",
  },
  borderRight: {
    borderRight: "1px solid #BCBCBC",
  },
  attachment: {
    color: "#5151EC",
    fontSize: "8px",
    fontWeight: 400,
    textDecoration: "underline",
    cursor: "pointer",
  },
  skuAttachmentsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
});
