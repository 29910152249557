import environment from "../environments/environment";

const URL = {
  USERS: {
    SIGNUP: `${environment.baseUrl}/digifac/signup`,
    SIGNIN: `${environment.baseUrl}/digifac/signin`,
    UPDATE_USER_COMPANY_DETAILS: `${environment.baseUrl}/pacpro/update/user_company_details`,
    UPDATE_VENDOR_PROFILE_INFO: `${environment.baseUrl}/digifac/vendor/details`,
    NEW_ADDRESS: `${environment.baseUrl}/digifac/vendor/address`,
    FETCH_VENDOR_DETAILS: `${environment.baseUrl}/digifac/vendor/details`,
    FETCH_CLIENT_REQUESTS: `${environment.baseUrl}/digifac/vendor/client_requests`,
    FETCH_CLIENT_REQUEST_FORM: (vendor_form_id: number) =>
      `${environment.baseUrl}/digifac/vendor/client_form/${vendor_form_id}`,
    SUBMIT_CLIENT_REQUEST_FORM: (vendor_form_id: any) =>
      `${environment.baseUrl}/digifac/vendor/client_form/${vendor_form_id}`,
    STATUS_CLIENT_REQUEST_FORM_UPDATE: (vendor_form_id: any) =>
      `${environment.baseUrl}/digifac/vendor/client_form/${vendor_form_id}/status`,
  },
  QUOTES: {
    QUOTE_LIST: `${environment.baseUrl}/digifac/vendor/quote_list`,
    QUOTE_DETAIL: (quote_id: number) =>
      `${environment.baseUrl}/digifac/vendor/quote_detail/${quote_id}`,
    FETCH_VENDOR_COMMENTS: (quote_id: number, vendor_id: number) =>
      `${environment.baseUrl}/pacpro/quote/${quote_id}/vendor/${vendor_id}/comment`,
    ADD_VENDOR_COMMENT: (quote_id: number, vendor_id: number) =>
      `${environment.baseUrl}/pacpro/quote/${quote_id}/vendor/${vendor_id}/comment`,
    UPDATE_QUOTE_VENDOR_DETAIL: (quote_id: number, qpmId: number) =>
      `${environment.baseUrl}/digifac/quote/${quote_id}/${qpmId}`,
    FETCH_SKU_ATTACHMENTS: (quote_id: number, sku_id: number) =>
      `${environment.baseUrl}/digifac/quote/${quote_id}/sku/${sku_id}/attachments`,
  },
  PURCHASE: {
    LIST: `${environment.baseUrl}/digifac/purchase_orders`,
    DETAIL: (id: number) =>
      `${environment.baseUrl}/digifac/purchase_orders/${id}`,
    ADD_DELIVERY_SCHEDULE: (id: number) =>
      `${environment.baseUrl}/digifac/purchase_orders/${id}/schedule`,
    EDIT_DELIVERY_SCHEDULE: (po_id: number, id: number) =>
      `${environment.baseUrl}/digifac/purchase_orders/${po_id}/schedule/${id}`,
    ADD_VENDOR_COMMENT: (id: number) =>
      `${environment.baseUrl}/pacpro/purchase_orders/${id}}/comment`,
    FETCH_VENDOR_DELIVERIES: `${environment.baseUrl}/digifac/delivery_schedules`,
  },
  DELIVERY: {
    DELIVERY_SCHEDULE_DATA: `${environment.baseUrl}/digifac/delivery_schedules`,
    VENDORS_CLIENT_LIST: `${environment.baseUrl}/digifac/vendors_client`,
    UPDATE_TRACK_LINK: (po_id: number, id: number) =>
      `${environment.baseUrl}/digifac/purchase_orders/${po_id}/schedule/${id}`,
  },
  CHECKLIST: {
    GET_PROPERTY_VALUES: `${environment.baseUrl}/digifac/get_delivery_product_check_list`,
    UPDATE_VENDOR_VALUE: `${environment.baseUrl}/digifac/delivery_vendor_update`,
  },
  SKU: {
    OPTIONS: `${environment.baseUrl}/pacpro/sku/list`,
    GET_SKU_LIST: `${environment.baseUrl}/digifac/skus`,
    GET_SKU_CLIENTS: `${environment.baseUrl}/digifac/clients`,
    GET_SKU_TAGS: `${environment.baseUrl}/pacpro/sku/tags`,
    GET_SKU_QUALITY_CHECKS_AND_ATTACHMENTS: (sku_id: number) =>
      `${environment.baseUrl}/pacpro/sku/${sku_id}/quality_checks_and_attachments`,
    GET_SKU_PRODUCT_PROPERTY: `${environment.baseUrl}/pacpro/skus/quality_checks`,
    GET_SKU_DETAILS: (id: number) => `${environment.baseUrl}/pacpro/sku/${id}`,
    GET_SKU_DELIVERY_SCHEDULE: (id: number) =>
      `${environment.baseUrl}/pacpro/sku/${id}/schedule`,
    GET_SKU_QUALITY_CHECKS: (id: number) =>
      `${environment.baseUrl}/pacpro/sku/${id}/quality_checks`,
  },
  QUALITY_CHECK: {
    GET_QUALITY_CHECK_LIST: `${environment.baseUrl}/digifac/skus/quality_check_list`,
    GET_QUALITY_CHECK_DETAILS: (prpd_id: number) =>
      `${environment.baseUrl}/digifac/skus/${prpd_id}/quality_check_details`,
  },
};

export default URL;
