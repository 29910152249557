import io from "socket.io-client";
import environment from "../environments/environment";

class SocketService {
  private static instance: SocketService | null = null;
  private readonly socket: any = null;

  private constructor() {
    this.socket = io(environment.socketUrl, { reconnectionAttempts: 5 });
  }

  static getInstance(): SocketService {
    if (!SocketService.instance) {
      SocketService.instance = new SocketService();
    }
    return SocketService.instance;
  }

  getSocket() {
    return this.socket;
  }
}

export default SocketService;
