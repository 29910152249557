import React from "react";
import "./Loader.scss";

const Loader: React.FC = () => (
  <div className="loader-custom">
    <div className="preloader-inner">
      <div className="preloader-icon">
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
);
export default Loader;
