import React, { useRef, useEffect } from "react";

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const useOutsideAlerter = (ref: any, action: () => void, dependency: any[]) => {
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      action();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, ...dependency]);
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const OutsideAlerter: React.FC<{
  children: any;
  action: () => void;
  dependency?: any[];
  className?: string;
}> = ({ children, action, dependency, className = "" }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, action, dependency || []);
  return (
    <div ref={wrapperRef} className={className}>
      {children}
    </div>
  );
};

export default OutsideAlerter;
