// src/redux/user/userSelector.ts
import { createSelector } from "reselect";
import { RootState } from "../store";
import { UserState } from "./userReducer";
import { commaFormat, formatDate, isNotNull } from "../../utils/Helper";
import { useState } from "react";

// Non-memoized selector function to access the user state from the Redux store
const selectUserState = (state: RootState): UserState => state.user;

// Memoized selector using createSelector
export const selectUser = createSelector(
  [selectUserState],
  (userState) => userState,
);

export const isAuthenticated = createSelector([selectUserState], (userState) =>
  isNotNull(userState.jwtToken),
);
export const selectAuthToken = createSelector(
  [selectUserState],
  (userState) => userState.jwtToken,
);
export const isUserLoading = createSelector(
  [selectUserState],
  (userState) => userState.loading,
);

export const userDetails = createSelector(
  [selectUserState],
  (userState) => userState,
);

export const newNotification = createSelector(
  [selectUserState],
  (userState) => userState.newNotification,
);

export const getUserAddresses = createSelector(
  [selectUserState],
  (userState) => userState.addresses,
);

export const getUserBillingAddresses = createSelector(
  [selectUserState],
  (userState) =>
    userState.addresses.filter((a) => a.address_type === "BILLING"),
);

export const getUserWarehouseAddresses = createSelector(
  [selectUserState],
  (userState) =>
    userState.addresses.filter((a) => a.address_type === "WAREHOUSE"),
);

export const getUserCompanyDetails = createSelector(
  [selectUserState],
  (userState) => userState.company_details,
);

export const selectAllProducts = createSelector(
  [selectUserState],
  (userState) => userState.products,
);

export const selectProfilePercentageCompletion = createSelector(
  [selectUserState],
  (userState) => {
    let percent = 0;
    if (userState.avatar && userState.phone_number) {
      percent = percent + 10;
    }
    if (userState.addresses.length > 0) {
      percent = percent + 20;
    }
    if (userState.company_details?.gst_number) {
      percent = percent + 10;
    }
    if (userState.company_details?.capacity) {
      percent = percent + 10;
    }
    if (userState.vendor_machines?.length > 0) {
      percent = percent + 15;
    }
    if (userState.vendor_images?.length > 0) {
      percent = percent + 15;
    }
    if (userState.vendor_products?.length > 0) {
      percent = percent + 20;
    }
    return percent;
  },
);

export const selectVendorProducts = createSelector(
  [selectUserState],
  (userState) =>
    userState.products?.filter(
      (product) => userState.vendor_products.indexOf(product.id) > -1,
    ),
);

export const selectVendorMachines = createSelector(
  [selectUserState],
  (userState) => userState.vendor_machines,
);

export const selectVendorImages = createSelector(
  [selectUserState],
  (userState) => userState.vendor_images,
);

export const selectVendorStats = createSelector(
  [selectUserState],
  (userState) => {
    return {
      total_quotes: userState.quotes,
      total_pos: userState.total_pos,
      total_orders: commaFormat(userState.total_order?.toString() || ""),
      nextDeliveryDate: formatDate(userState.next_delivery),
    };
  },
);
