import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import "./XetModal.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "none",
  outline: "none",
};
interface XetModalProps {
  open: boolean;
  handleClose: () => void;
  children: React.ReactElement;
  backdropClose?: boolean;
}

const XetModal: React.FC<XetModalProps> = ({
  open,
  handleClose,
  children,
  backdropClose = true,
}) => {
  return (
    <Modal open={open} onClose={backdropClose ? handleClose : undefined}>
      <Box sx={style as never}>{children}</Box>
    </Modal>
  );
};

export default XetModal;
