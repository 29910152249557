import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectProfilePercentageCompletion,
  userDetails,
} from "../../redux/user/userSelector";
import FactoryImages from "../FactoryImages/FactoryImages";
import ProfileAddress from "../ProfileAddress/ProfileAddress";
import ProfileFactoryMachines from "../ProfileFactoryMachines/ProfileFactoryMachines";
import ProfileInfoEditPopup from "../ProfileInfoEditPopup/ProfileInfoEditPopup";
import ProfileProductTagging from "../ProfileProductTagging/ProfileProductTagging";
import XetModal from "../shared/xet-modal/XetModal";
import VendorStats from "../VendorStats/VendorStats";
import "./VendorProfileDetail.scss";

const VendorProfileDetail: React.FC = () => {
  const user = useSelector(userDetails);
  const profileCompletion = useSelector(selectProfilePercentageCompletion);
  const [profileInfoEdit, setProfileInfoEdit] = useState(false);

  return (
    <div className="vendor-detail-container p-16 flex-column gap-12">
      {profileCompletion < 100 && (
        <div className="profile-complete-container p-16 flex-column gap-8">
          <p className="xetgo-font-button bold" style={{ color: "#383838" }}>
            Complete Your Profile
          </p>
          <p className="xetgo-font-tag" style={{ color: "#8F8F8F" }}>
            By completing all the details you have a higher chance of getting
            seen by Clients.
          </p>
          <div className="progress-container flex-row gap-12 align-items-center">
            <p className="xetgo-font-tag bold" style={{ color: "#000000" }}>
              {profileCompletion} %
            </p>
            <div className="flex-1 profile-progress-background">
              <div
                className="profile-progress"
                style={{ width: `${profileCompletion}%` }}
              ></div>
            </div>
          </div>
        </div>
      )}
      <div className="profile-name-container p-16 flex-column gap-16">
        <div className="flex-row justify-content-space-between">
          <div className="flex-row gap-12 align-items-center">
            <img
              src="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/35af6a41332353.57a1ce913e889.jpg"
              width={40}
              height={40}
              alt="profile-photo"
              className="border-round"
            />
            <div
              className="flex-column gap-4 xetgo-font-tag"
              style={{ color: "#FFFFFF" }}
            >
              <p className="bolder">{user.company_details?.name}</p>
              <p>
                {user.name},
                <span style={{ color: "#B3B3B3", marginLeft: "4px" }}>
                  {user.phone_number}
                </span>
              </p>
            </div>
            <div className="factory-counter flex-row gap-10 align-items-center px-12 py-6">
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240506081302-business.svg"
                width={12}
                height={12}
                alt="factory"
              />
              <p className="xetgo-font-tag">
                {user.addresses.length} Factories
              </p>
            </div>
          </div>
          <div className="gst-container py-12 px-16 xetgo-font-tag bold flex-column gap-8">
            {user.company_details &&
              user.company_details.trade_license_number && (
                <p>
                  TRADE LICENSE No. :
                  {user.company_details?.trade_license_number}
                </p>
              )}
            <p>GST : {user.company_details?.gst_number}</p>
            <p>PAN : {user.company_details?.pan_number}</p>
          </div>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240506082013-pen-square.svg"
            width={16}
            height={16}
            alt="edit"
            className="edit-banner-icon cursor-pointer"
            onClick={() => setProfileInfoEdit(true)}
          />
        </div>
        <div className="flex-row justify-content-space-between">
          <div className="flex-column gap-4">
            <p className="xetgo-font-tag" style={{ color: "#FFFFFF" }}>
              {` ${user.company_details?.capacity} 
                ${user.company_details?.capacity_unit}`}
            </p>
            {user.company_details && user.company_details.employee_count && (
              <p className="xetgo-font-tag" style={{ color: "#FFFFFF" }}>
                {user.company_details?.employee_count} Employees
              </p>
            )}
          </div>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240506082013-pen-square.svg"
            width={16}
            height={16}
            alt="edit"
            className="edit-banner-lower-icon cursor-pointer"
            onClick={() => setProfileInfoEdit(true)}
          />
          <p
            className="xetgo-font-tag gst-banner-lower-icon"
            style={{ color: "#FFFFFF" }}
          >
            GST : {user.company_details?.gst_number}
          </p>
        </div>
      </div>
      <VendorStats />
      <ProfileAddress />
      <ProfileProductTagging />
      <ProfileFactoryMachines />
      <FactoryImages />
      <XetModal
        backdropClose={true}
        open={profileInfoEdit}
        handleClose={() => setProfileInfoEdit(false)}
      >
        <ProfileInfoEditPopup onCancel={() => setProfileInfoEdit(false)} />
      </XetModal>
    </div>
  );
};

export default VendorProfileDetail;
