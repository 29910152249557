import { ToastPosition } from "react-toastify";

export const toastOptions = {
  position: "top-right" as ToastPosition,
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};
