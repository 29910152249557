import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import "./ClientRequestForms.scss";
import { useNavigate } from "react-router-dom";
import { VENDOR_FORM_STATUS_MAP } from "../../constants/VendorForm.data";

interface ClientRequest {
  uuid: string;
  company_name: string;
  vendor_form_id: number;
  vendor_form_name: string;
  form_status: string;
  company_poc: {
    poc_name: string;
    poc_phone: string;
  };
}

const ClientRequestForms: React.FC = () => {
  const navigate = useNavigate();
  const [clientRequests, setClientRequests] = useState<ClientRequest[]>([]);

  useEffect(() => {
    fetchClientRequests().then((r) => r);
  }, []);

  const fetchClientRequests = async () => {
    try {
      const { data } = await ApiService().client.get(
        URL.USERS.FETCH_CLIENT_REQUESTS
      );
      setClientRequests(data.data);
    } catch (error) {
      console.log("Error fetching client forms", error);
    }
  };

  return (
    <div className="client-request-forms">
      <table>
        <thead>
          <tr>
            <th>Client Id</th>

            <th className="flex-row align-items-center gap-8">
              <img
                height="12"
                width="12"
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240515083543-table-properties.svg"
                alt="name-icon"
              />
              Name
            </th>

            <th>
              <span className="flex-row align-items-center gap-8">
                <img
                  height="12"
                  width="12"
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240822130407-Frame+1321314714.svg"
                  alt="form-icon"
                />
                Form Name
              </span>
            </th>

            <th className="flex-row align-items-center gap-8">
              <img
                height="12"
                width="12"
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240520063058-user.svg"
                alt="poc-icon"
              />
              POC
            </th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {clientRequests.map((request, index) => (
            <tr
              key={index}
              className="client-request-row"
              onClick={() => navigate(`/vendor-form/${request.vendor_form_id}`)}
            >
              <td className="table-cell">
                <p className="client-id px-12 py-6">{request.uuid}</p>
              </td>
              <td className="table-cell client-name">{request.company_name}</td>
              <td className="table-cell form-name">
                {request.vendor_form_name}
              </td>
              <td className="table-cell poc-details">
                <span className="poc-name">
                  {request.company_poc.poc_name}{" "}
                </span>
                <span className="poc-mobile">
                  Mob: {request.company_poc.poc_phone}
                </span>
              </td>
              <td className="table-cell form-status">
                <button
                  className="status-btn gap-10"
                  style={{
                    background:
                      VENDOR_FORM_STATUS_MAP[request.form_status]
                        ?.backgroundColor,
                    color: VENDOR_FORM_STATUS_MAP[request.form_status]?.color,
                  }}
                >
                  {VENDOR_FORM_STATUS_MAP[request.form_status]?.name}
                  {request.form_status !== "DECLINED" &&
                    request.form_status !== "APPROVED" &&
                    request.form_status !== "REJECTED" && <FaArrowRight />}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClientRequestForms;
