import "./Header.scss";
import React, { useState } from "react";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import SideNav from "../SideNav/SideNav";

const Header: React.FC = () => {
  const [openHamburger, setOpenHamburger] = useState(false);

  return (
    <>
      <div className="header-section full-width flex-row justify-content-space-between align-items-center">
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240212095733-xetgo_horizontal-2.svg"
          alt="logo"
          width={82}
          height={32}
        />
        <OutsideAlerter action={() => setOpenHamburger(false)}>
          <div className="position-relative">
            <img
              height={24}
              width={24}
              onClick={() => setOpenHamburger(!openHamburger)}
              className="cursor-pointer"
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240417054803-menu.svg"
              alt="hamburger-icon"
            />
            {openHamburger && (
              <div className="sidenav-modal-box">
                <SideNav showRight={true}></SideNav>
              </div>
            )}
          </div>
        </OutsideAlerter>
        {openHamburger && <div className="sidenav-modal-background"></div>}
      </div>
    </>
  );
};

export default Header;
