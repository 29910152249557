import React, { useEffect, useState } from "react";
import "./Profile.scss";
import { useDispatch } from "react-redux";
import { fetchVendorDetailsStart } from "../../redux/user/userAction";
import VendorProfileDetail from "../../components/VendorProfileDetail/VendorProfileDetail";
import ClientRequestForms from "../../components/ClientRequestForms/ClientRequestForms";
import { useNavigate, useSearchParams } from "react-router-dom";

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<"DETAILS" | "CLIENT_REQUESTS">(
    "DETAILS"
  );

  useEffect(() => {
    dispatch(fetchVendorDetailsStart());
  }, []);

  useEffect(() => {
    if (searchParams.get("tab") === "client_requests") {
      setActiveTab("CLIENT_REQUESTS");
    } else {
      setActiveTab("DETAILS");
    }
  }, [searchParams]);

  const tabClicked = (tab: string) => {
    if (tab === "Details") {
      navigate("/profile");
    } else {
      navigate(`/profile?tab=${tab}`);
    }
  };

  return (
    <div className="main-content-container flex-column">
      <div className="breadcrumb-container px-32 py-12 flex-row gap-4 xetgo-font-tag">
        <span className="bold" style={{ color: "#AA5200" }}>
          Digifac
        </span>
        <span>/</span>
        <span> Company Profile</span>
      </div>

      <div className="details-page-tab-container flex-row align-items-center">
        <span
          className={`tab ${activeTab === "DETAILS" && "active"}`}
          onClick={() => tabClicked("Details")}
        >
          Details
        </span>

        <span
          className={`tab ${activeTab === "CLIENT_REQUESTS" && "active"}`}
          onClick={() => tabClicked("client_requests")}
        >
          Client Requests
        </span>
      </div>
      {activeTab === "DETAILS" ? (
        <VendorProfileDetail />
      ) : (
        <ClientRequestForms />
      )}
    </div>
  );
};
export default Profile;
